<script context="module" lang="ts">
  import type { Readable } from "svelte/motion";
  import { derived, readable, writable, type Writable } from "svelte/store";
  import hash from "$utils/hash";

  // function read(init?: Record<string, any> | Readable<Record<string, any>>): Readable<Record<string, any> {
  //   if(init?.subscribe) return init as Readable<Record<string, any>>;
  //   return readable<Record<string, any>>(init ?? {});
  // }

  function changed(
    prev: string | Record<string, any>,
    next: Record<string, any>
  ): boolean | string {
    return false;
  }

  function valued(
    init?: Record<string, any> | Writable<Record<string, any>>
  ): Writable<Record<string, any>> {
    if (init?.update) return init as Writable<Record<string, any>>;
    return writable<Record<string, any>>(init ?? {});
  }

  const preflight = debounce(
    function (data: FormData | Record<string, string>, issue = false) {
      return fetchPermitCreate(data, issue);
    },
    1000,
    { leading: true, trailing: true }
  );

  // there's some duplicate logic we should probably extract
  function validate(
    policy: Readable<PermitIssuePolicy>,
    values: Readable<Record<string, any>>
  ): Readable<boolean> {
    return derived([policy, values], ([$policy, $values]) => {
      if (!$policy) return false;
      if (!!$policy?.vehicle?.required && !$values.vehicle) return false;
      if (!!$policy?.space?.required && !$values.space) return false;
      if (
        !!$policy?.permit?.tenant?.required &&
        !$values[$policy?.permit?.tenant?.param]
      )
        return false;
      if (!!$policy?.media?.required && !$values.media) return false;
      if (!!$policy?.agreement?.required && !$values.agreement) return false;
      if (!!$policy?.name?.required && !$values.name) return false;
      if (!!$policy?.tel?.required && !$values.tel) return false;
      if (!!$policy?.email?.required && !$values.email) return false;
      if (
        !!$policy?.permit?.authentication?.required &&
        !$values[$policy?.permit?.authentication?.param]
      )
        return false;
      return true;
    });
  }

  // evaluate values for the minimum set that we need to evaluate for blocks and pricing
  function statusable(
    policy: Readable<PermitIssuePolicy>,
    values: Readable<Record<string, any>>
  ): Readable<Record<string, any>> {
    return derived<[typeof policy, typeof values], any | null>(
      [policy, values],
      ([$policy, $values]) => {
        logger("statusable", $policy, $values);

        // null as long as policy validations don't pass
        if (!$policy) return null;
        if (!$values) return null;
        if (!!$policy?.vehicle?.required && !$values.vehicle) return null;
        if (!!$policy?.space?.required && !$values.space) return null;
        if (
          !!$policy?.permit?.tenant?.required &&
          !$values[$policy?.permit?.tenant?.param]
        )
          return null;
        if (!!$policy?.media?.required && !$values.media) return null;
        if (
          !!$policy?.permit?.authentication?.required &&
          !$values[$policy?.permit?.authentication?.param]
        )
          return null; // policy level auth token
        //if (!!$policy?.agreement?.required && !$values.agreement)
        //return set(null);
        //if (!!$policy?.name?.required && !$values.name) return set(null);
        //if (!!$policy?.tel?.required && !$values.tel) return set(null);
        //if (!!$policy?.email?.required && !$values.email) return set(null);

        const data = omit(
          {
            policy: $policy.policy,
            scope: $policy.scope,
            ...$values,
          },
          ["name", "agreement", "email", "tel", "notes"]
        );

        return data;
      }
    );
  }

  function status(
    policy: Readable<PermitIssuePolicy>,
    values: Readable<Record<string, any>>
  ): Readable<any> {
    // const pstore = readable(policy);
    let hashed: string | null;
    const valued = statusable(policy, values);
    return derived<[typeof policy, typeof valued], any>(
      [policy, valued],
      ([$policy, $values], set) => {
        // null as long as policy validations don't pass

        if (!$values) {
          logger("unable to status");
          return set((hashed = null));
        }

        // we did all the work in statusable
        const data = $values;
        // omit(
        //   {
        //     policy: $policy.policy,
        //     scope: $policy.scope,
        //     ...$values,
        //   },
        //   ["name", "agreement", "email", "tel", "notes", "payment"]
        // );

        hash(data).then((test) => {
          logger("values hash=", test, hashed);
          if (test === hashed) return;
          hashed = test;

          set({
            status: 202,
          });

          // debounced preflight
          preflight(data, false).then((json) => {
            if (json.status == 400 && !json.param) json.param = "valid";
            if (json.status == 401 && !json.param)
              json.param =
                $policy.permit?.authentication?.param ?? "authentication";
            set(json);
          });

          // fetchPermitCreate(
          //   {
          //     policy: $policy.policy,
          //     scope: $policy.scope,
          //     ...$values,
          //   },
          //   false
          // ).then((json) => {
          //   set(json);
          //   return json;
          // });
        });

        //const test = hash($values);

        // check permit issue policy
      }
    );
  }

  //const nowutc = instant("PT1M");

  async function create(
    policy: PermitIssuePolicy,
    values: Record<string, any>
  ): Promise<any> {}

  const steps: Record<string, ComponentType<SvelteComponent>> = {
    valid: PolicyPermitValidStep,
    space: PolicySpaceStep,
    tenant: PolicyTenantStep,
    media: PolicyMediaStep,
    vehicle: PolicyPermitVehicleStep,
    info: PolicyPermitInfoStep,
    files: PolicyPermitFilesStep,
    payment: PolicyPermitPaymentStep,
    send: PolicyPermitSendStep,
    complete: PolicyPermitComplete,
    auth: PolicyPermitAuthStep,
  };
</script>

<script lang="ts">
  import Form from "$components/form/Form.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import { query } from "$utils/router";
  import PolicyAgreementFieldItem from "./PolicyAgreementFieldItem.svelte";
  import PolicyTenantStep from "./PolicyPermitTenantStep.svelte";
  import PolicySpaceStep from "./PolicyPermitSpaceStep.svelte";
  import PolicyMediaStep from "./PolicyPermitMediaStep.svelte";
  import { fetchPermitCreate } from "$components/permit/api";
  import PolicyPermitPaymentStep from "./PolicyPermitPaymentStep.svelte";
  import { debounce, omit, pick } from "lodash-es";
  import PolicyPermitVehicleStep from "./PolicyPermitVehicleStep.svelte";
  import PolicyPermitSendStep from "./PolicyPermitSendStep.svelte";
  import PolicyPermitComplete from "./PolicyPermitComplete.svelte";
  import PolicyPermitFilesStep from "./PolicyPermitFilesStep.svelte";
  import PolicyPermitValidStep from "./PolicyPermitValidStep.svelte";
  import PolicyPermitInfoStep from "./PolicyPermitInfoStep.svelte";
  import {
    createEventDispatcher,
    type ComponentType,
    type SvelteComponent,
  } from "svelte";
  import PolicyPermitAuthStep from "./PolicyPermitAuthStep.svelte";
  import { save as saveVehicle } from "$components/vehicle";

  let classname: string = "";
  export { classname as class };

  export let policy: PermitIssuePolicy;
  // we need a mechanic for intial but updateable values
  // readonly values
  // export let readonly: Record<string, any> = {};
  // export let init: Record<string, any> = {};
  export let values: Record<string, any> = {};
  export let state: Writable<Record<string, any>> = writable({});

  const events = createEventDispatcher<{ create: Permit }>();

  const flow = [
    steps.valid,
    steps.space,
    steps.tenant,
    steps.media,
    steps.vehicle,
    steps.auth,
    // steps.info,
    // steps.files,
    // steps.payment,
    // steps.send,
    // steps.complete,
  ];

  //let submittable = false;
  let submitting = false;
  let permit: Permit | null = null;

  const external = valued(values);
  const internal = valued(state ?? {});
  //const files: Writable<File[]> = writable([]);
  const stated = derived([external, internal], ([a, b]) => ({ ...a, ...b }));
  const pol = writable(policy);

  // pull data we need validate pricing
  // we only need to validate external values from query
  const statable = statusable(pol, stated);
  const stat = status(pol, statable);
  const error = derived<typeof stat, { [x: string]: any } | false>(
    stat,
    ($stat) => {
      if (!$stat) return false;
      if (!$stat.param) return false;
      if ($stat.status >= 400 && $stat.status < 500) {
        return {
          [$stat.param ?? "valid"]: $stat,
        };
      }
      return false;
    }
  );

  // validate is an assertion that submit will pass (sans payment)

  const validated = validate(pol, stated);
  const submittable = derived<
    [typeof validated, typeof stated, typeof pol],
    boolean
  >(
    [validated, stated, pol],
    ([$validated, $stated, $policy]) =>
      !!$validated &&
      (!$policy.agreement?.required || !!$stated.agreement) &&
      ($stated?.upload?.length ?? 0) >= $policy.files.required
  );
  // if policy has no pricing we could probably tap in here
  const payment = derived(
    [pol, stat],
    ([$policy, $stat]) =>
      $stat?.payment ??
      ($stat?.status == 200
        ? { required: false, total: { display: "Free", value: 0 } }
        : null)
  );

  $: logger(
    "stat=",
    $stat,
    "priceable=",
    $statable,
    "payment=",
    $payment,
    "submittable=",
    $submittable
  );

  $: validating = null == $stat ? null : $stat.status == 202;
  //$: submittable = !!$validated;

  // as long as we update vals, we'll get a more-or-less up-to-date status of the policy issue ability

  const queryparams = [
    "duration",
    "start",
    "end",
    "space",
    "tenant",
    "media",
    "vehicle",
  ];

  function onchange(newvalues: Record<string, any>) {
    query(pick(newvalues, queryparams), {
      history: false,
    });

    //logger("change internal=", omit(newvalues, queryparams));

    newvalues = omit(newvalues, queryparams);

    internal.update((values) => {
      values = {
        ...values,
        ...newvalues,
      };

      // specific values that are autocreated from this

      return values;
    });
  }

  // change can change the internal state
  function change(
    newvalues: Record<string, any> | CustomEvent<Record<string, any>>
  ) {
    //logger("policypermitcreate.change", newvalues);

    if (newvalues instanceof CustomEvent) {
      newvalues = newvalues.detail;
    }

    onchange(newvalues);
  }
  // input values (e.g. params) are gonna override the values
  // overwrite external on any values change
  $: external.set(values);
  $: pol.set(policy);
  //$: change(values);

  async function submit() {
    submitting = true;

    var result = await fetchPermitCreate(
      {
        ...$statable,
        ...$stated,
      },
      true
    );

    // result should be json...
    permit = result?.items?.[result?.permits?.item] ?? result?.permits?.item;

    if (!permit && result.message) alert(result.message);

    logger("permit=", permit);
  }

  function complete() {
    // we should be done with sending, have a permit, and be ready to go
    if (!permit) return; // no permit to handle
    // save vehicle - or do this elsewhere?
    if (permit.vehicle) {
      // save vehicle
      saveVehicle(permit.vehicle);
    }
    events("create", permit);
    //ocation.href = permit.url; // away we go
    // internal handle?
  }
</script>

<Form class={[classname, "policy permit create"].filter(Boolean).join(" ")}>
  <!-- <UiHeader> -->
  <!-- <UiTitle>{policy.title}</UiTitle> -->
  <slot></slot>
  <!-- </UiHeader> -->
  <svelte:component
    this={flow[0]}
    {policy}
    values={$stated}
    {onchange}
    on:change={change}
    error={$error}
  >
    <svelte:component
      this={flow[1]}
      {policy}
      values={$stated}
      {onchange}
      on:change={change}
      error={$error}
    >
      <svelte:component
        this={flow[2]}
        {policy}
        values={$stated}
        {onchange}
        on:change={change}
        error={$error}
      >
        <svelte:component
          this={flow[3]}
          {policy}
          values={$stated}
          {onchange}
          on:change={change}
          error={$error}
        >
          <svelte:component
            this={flow[4]}
            {policy}
            values={$stated}
            {onchange}
            on:change={change}
            error={$error}
          >
            <svelte:component
              this={flow[5]}
              {policy}
              values={$stated}
              {onchange}
              on:change={change}
              error={$error}
            >
              <PolicyPermitInfoStep
                {policy}
                values={$stated}
                {onchange}
                on:change={change}
              ></PolicyPermitInfoStep>

              <PolicyPermitSendStep
                {permit}
                {policy}
                values={$stated}
                on:complete={complete}
              >
                <PolicyPermitFilesStep {policy} {onchange} on:change={change} />
                {#if $error}
                  <PolicyPermitComplete {policy} />
                {:else}
                  <PolicyPermitPaymentStep
                    {policy}
                    payment={$payment}
                    submittable={$submittable}
                    {submitting}
                    validating={!!validating}
                    {onchange}
                    on:change={change}
                    on:submit={submit}
                  >
                    <UiFormFieldList>
                      <PolicyAgreementFieldItem
                        {policy}
                        values={$stated}
                        {onchange}
                        on:change={change}
                      />
                    </UiFormFieldList>
                  </PolicyPermitPaymentStep>
                {/if}
              </PolicyPermitSendStep>
            </svelte:component>
          </svelte:component>
        </svelte:component>
      </svelte:component>
    </svelte:component>
  </svelte:component>
</Form>
