<svelte:options runes={true} />

<script lang="ts">
  import { Vehicle } from ".";
  const {
    onvehicle,
    class: classname = null,
    source,
  } = $props<{
    class?: string | null | undefined;
    onvehicle: (vehicle: Vehicle | null) => void;
    source: Vehicles;
  }>();

  function onchange(e: Event) {
    const id = (e.target as HTMLSelectElement).value;

    const vehicle = source?.items?.[id] ?? null;
    logger("onchange", e, source, id, vehicle);
    onvehicle?.(vehicle);
  }
</script>

<select
  {onchange}
  class={["vehicles history", classname].filter(Boolean).join(" ")}
>
  <option value="">--</option>
  {#each Object.entries(source?.items ?? {}) as [id, item]}
    <option value={item.id ?? item.key ?? item.plate ?? item.display}
      >{item.display}</option
    >
  {/each}
</select>
