<script context="module" lang="ts">
  function debounce(fn: Function): Function {
    let raf: number | undefined;

    return (...args: any[]) => {
      if (raf) {
        //logger("debounced");
        return;
      }

      raf = requestAnimationFrame(() => {
        fn(...args); // run useful code
        raf = undefined;
      });
    };
  }
</script>

<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";

  //import resize from "svelte-use-resize-observer";
  import { resize } from "svelte-resize-observer-action";
  import { getFrame, imageDataToBlob } from "@parkingboss/barcam";

  import QRScanner from "qr-scanner";

  const dispatch = createEventDispatcher<{
    frame: { blob: Blob };
    barcode: { barcode: string; blob: Blob };
  }>();

  //const qrengine = QRScanner.createQrEngine();

  //logger("engine=", qrengine);

  // Component API
  export function scrollIntoView(opts?: boolean | ScrollIntoViewOptions) {
    figureEl?.scrollIntoView(opts);
  }

  // Params
  let mode;
  export { mode as class };
  export let cover: string | null | undefined = null;
  export let upload = true;
  export let barcode = false;

  // Element Bindings
  let figureEl: HTMLElement | null = null;
  let videoEl: HTMLVideoElement | null = null;
  let qrscanner: QRScanner | null = null;
  const torch = {
    enabled: false,
    active: false,
  };

  //   qrScanner.hasFlash(); // check whether the browser and used camera support turning the flash on; async.
  // qrScanner.isFlashOn(); // check whether the flash is on
  // qrScanner.turnFlashOn(); // turn the flash on if supported; async
  // qrScanner.turnFlashOff(); // turn the flash off if supported; async
  // qrScanner.toggleFlash();

  // State
  let loading = true;
  let fallback = false;

  $: if (videoEl && !qrscanner)
    qrscanner = new QRScanner(videoEl, onresult, {
      returnDetailedScanResult: true,
      maxScansPerSecond: barcode ? undefined : 0,
      onDecodeError: function () {},
      // calculateScanRegion: ($video) => ({
      //   x: 0,
      //   y: 0,
      //   width: $video.videoWidth,
      //   height: $video.videoHeight,
      //   downScaledWidth: $video.videoWidth,
      //   downScaledHeight: $video.videoHeight,
      // }),
      //highlightScanRegion: true,
      //highlightCodeOutline: true,
    });

  $: if (qrscanner) {
    startStream();
  }

  function onresult(result) {
    //logger("decoded qr code:", result);
    fireBarcode({ barcode: result.data, blob: null });
  }

  function startStream() {
    if (fallback) return;
    //logger("qrscanner=", qrscanner);
    qrscanner
      ?.start()
      .then(() => qrscanner as QRScanner)
      .then(async function (qrscanner) {
        //torch.active = !!qrscanner.isFlashOn();
        torch.enabled = !!(await qrscanner.hasFlash());
        if (torch.active) await qrscanner.turnFlashOn();
        torch.active = qrscanner.isFlashOn();
        return qrscanner;
      })
      .catch(function (error) {
        logger("error=", error);
        fallback = true;
      })
      .finally(() => {
        loading = false;
      });
  }

  function stopStream() {
    qrscanner?.stop();
    torch.enabled = false;
  }

  function onVisibilityChange() {
    if (document.hidden) {
      stopStream();
    } else {
      startStream();
    }
  }

  // async function turnTorchOn() {
  //   await qrscanner?.turnFlashOn();
  //   torch.active = !!qrscanner?.isFlashOn();
  // }

  // async function turnTorchOff() {
  //   await qrscanner?.turnFlashOff();
  //   torch.active = !qrscanner?.isFlashOn();
  // }

  async function toggleTorch() {
    await qrscanner?.toggleFlash();
    torch.active = !!qrscanner?.isFlashOn();
  }

  onMount(() => {
    // this is handled by qrscanner
    //document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      //document.removeEventListener("visibilitychange", onVisibilityChange);
      stopStream();
      qrscanner?.destroy();
      qrscanner = null;
    };
  });

  // Event Handlers:
  async function onFileChanged(evt) {
    const blob = evt.target.files[0];
    if (!blob) return;

    fireFrame({ blob });

    if (barcode) {
      QRScanner.scanImage(blob, {
        returnDetailedScanResult: true,
        //qrEngine: qrengine,
      })
        .then(function (result) {
          logger("result=", result);
          fireBarcode({ barcode: result.data, blob });
        })
        .catch(function (error) {
          logger("error=", error);
        });
    }
  }

  async function onClick() {
    if (cover) {
      return;
    }
    const imgData = await getFrame(videoEl, null);
    const blob = await imageDataToBlob(imgData);
    fireFrame({ blob });
    // if (barcode) {
    //   const result = await getBarcode(imgData);
    //   fireBarcode({ barcode: result, blob });
    // }
  }

  // Event Dispatchers
  function fireFrame({ blob }) {
    dispatch("frame", { blob });
  }

  function fireBarcode({ barcode, blob }) {
    dispatch("barcode", { barcode, blob });
  }

  const onresize = debounce(() => {
    //logger("resizing video", videoEl, qrscanner);
    //qrscanner?._updateOverlay();
    if (qrscanner)
      qrscanner._scanRegion = qrscanner._calculateScanRegion(qrscanner.$video);
    //logger("resized video", videoEl, qrscanner);
  });
</script>

<figure
  class="camera {mode}"
  bind:this={figureEl}
  class:loading
  class:video={!fallback}
>
  {#if fallback}
    <input
      type="file"
      accept="image/*"
      capture="environment"
      on:change={onFileChanged}
    />
  {:else if !loading}
    <button type="button" on:click={onClick}></button>
  {/if}

  <img src={cover || ""} alt="scanning preview" />

  {#if upload}
    <input type="file" accept="image/*" on:change={onFileChanged} />
  {/if}

  {#if torch.enabled}
    <input
      type="checkbox"
      name="torch"
      bind:checked={torch.active}
      on:change={toggleTorch}
    />
  {/if}

  <video
    bind:this={videoEl}
    playsinline
    autoplay
    muted
    use:resize={onresize}
    on:resize={onresize}
  ></video>

  {#if !loading && !fallback}
    <figcaption>
      <slot />
    </figcaption>
  {/if}
</figure>
