<script lang="ts" context="module">
  import { param } from "$utils/params";
  const level = param("level");
  const nowutc = instant("PT1M");
</script>

<script lang="ts">
  import { hover } from "$utils/behaviorstores";

  import { query } from "$utils/router";
  import MapFeatureClick from "$components/map/MapFeatureClick.svelte";
  import MapFeatureHover from "$components/map/MapFeatureHover.svelte";
  import MapFeatureState from "$components/map/MapFeatureState.svelte";
  import MapPopup from "$components/map/MapPopup.svelte";
  import ParkingAvailabilityLevels from "$components/parking/ParkingAvailabilityLevels.svelte";
  import ParkingAvailabilitySpaces from "$components/parking/ParkingAvailabilitySpaces.svelte";
  import PropertyMap from "$components/map/PropertyMap.svelte";
  import PropertyDataItem from "$components/property/PropertyDataItem.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import MapCompass from "$components/map/MapCompass.svelte";
  import type { MapGeoJSONFeature } from "$components/map/mapping";
  import SpacesDirectory from "$components/space/SpacesDirectory.svelte";
  import Form from "$components/form/Form.svelte";
  import { instant } from "$utils/temporal";
  import {
    PermitValidEffectiveInterval,
    PermitValidFromString,
    availableSpaces,
    spaces,
  } from "$components/policy";
  import PolicyPermitCreate from "$components/policy/PolicyPermitCreate.svelte";
  import { writable, type Writable } from "svelte/store";
  import PolicyPermitValidStep from "$components/policy/PolicyPermitValidStep.svelte";

  import { resolve } from "$components/space";

  export let policy: PermitIssuePolicy;

  //export let referrer: string | nullish = null;
  let space: string | null = null;
  //export let base: string | nullish = null;
  //export let valid: PermitValid;
  export let values: Record<string, string | string[]> = {};
  //export let values: Record<string, any> = {};

  const state: Writable<Record<string, any>> = writable({});

  //let valid: string | nullish;

  const filter = [
    "all",
    ["==", ["geometry-type"], "Polygon"],
    ["==", ["get", "amenity"], "parking_space"],
    ["has", "ref:boss:subject"],
  ];

  let hovered: MapGeoJSONFeature;

  $: logger("policyspacemapsection.values=", values);

  $: space = (values.space as string) ?? null;
  $: property = policy.property;
  $: issue = policy.issue.enabled;
  $: continuous = !!policy?.permit?.continuous;

  $: nowzoned = $nowutc.withTimeZone(policy.timezone);

  //logger("property", property);

  let levels;

  $: valid = PermitValidEffectiveInterval(
    PermitValidFromString(policy, values),
    nowzoned
  );

  // $: logger(
  //   "space map valid = ",
  //   parsed.map((i) => i.toString())
  // );

  $: policySpaces = spaces(policy);

  $: policySpacesByLevel = Object.values(policySpaces.items).reduce(
    (levels, item) => {
      for (let l of item.level?.split ? item.level.split(";") : [""]) {
        if ("" == l) l = "outside";
        if (!levels[l])
          levels[l] = {
            "level:ref": "outside" === l ? "Outside" : `Floor ${l}`,
            items: {},
            level: l,
            count: 0,
            available: 0,
          };
        levels[l].count++;
        if (item["level:ref"]) levels[l]["level:ref"] = item["level:ref"];
        levels[l].items[item.id] = item;
      }

      return levels;
    },
    {} as ByLevels<Space>
  ) as ByLevels<Space>;

  $: record = resolve(space, policySpaces);

  $: logger("space=", space, record);

  $: availablePolicySpaces = availableSpaces(policy, valid);

  function initByLevelAvailability(byLevel: ByLevels<Space>): ByLevels<Space> {
    const result: ByLevels<Space> = {};
    for (const [key, value] of Object.entries(byLevel ?? {})) {
      result[key] = {
        ...value,
        items: {},
        available: 0,
      };
    }

    return result;

    // return  {
    //   ...byLevel
    // }
    // for (const item of Object.values(byLevel ?? {})) {
    //   item.available = 0;

    // }
    // return byLevel;
  }

  $: availablePolicySpacesByLevel = Object.values(
    availablePolicySpaces.items
  ).reduce((levels: ByLevels<Space>, item) => {
    //logger("item=", item);

    for (let l of item.level?.split ? item.level.split(";") : [""]) {
      if ("" == l) l = "outside";
      var level = levels?.[l];
      if (!level) continue;

      level.available ??= 0;
      level.available++;
      level.items[item.id] = item;
    }

    return levels;
  }, initByLevelAvailability(policySpacesByLevel)) as ByLevels<Space>;

  $: logger(
    "policy spaces=",
    policySpaces,
    "availablility=",

    availablePolicySpaces,
    availablePolicySpacesByLevel
  );

  // $: allLevels = sortBy(
  //   Object.entries(availability ?? {}),
  //   ([level]) => level
  // ).map(([spacelevel, item]) => item);

  $: levelsWithAvailablity = Object.values(
    (availablePolicySpacesByLevel ?? {}) as ByLevels<Space>
  ).filter((i) => !!i.available);

  // change level if necessary
  $: if (
    valid &&
    !space &&
    !record &&
    levelsWithAvailablity.length &&
    !availablePolicySpacesByLevel[$level]
  )
    query(
      {
        level: levelsWithAvailablity[0].level,
      },
      { history: false }
    );

  $: if (space || !space || $level || !$level) scrollTo(0, 0);

  $: hoveredItem =
    availablePolicySpaces.items[hovered?.properties?.["ref:boss:subject"]];

  //$: logger("hoveredItem", hoveredItem);

  // if space is no longer available, clear it
  $: if (record?.id && !availablePolicySpaces.items[record.id])
    query({ space: null });

  function create(e: Permit | CustomEvent<Permit>) {
    if (e instanceof CustomEvent) {
      location.href = e.detail.url;
    } else {
      location.href = e.url;
    }
  }
  function change(
    newvalues: Record<string, any> | CustomEvent<Record<string, any>>
  ) {
    logger("policyspacemap.change", newvalues);

    if (newvalues instanceof CustomEvent) {
      newvalues = newvalues.detail;
    }

    //logger("change query=", pick(newvalues, queryparams));

    query(newvalues, {
      history: false,
    });
  }
</script>

<svelte:head>
  <meta name="theme-color" content="#fff" />
  <title
    >{(property &&
      [policy?.title, property?.name].filter(Boolean).join(" - ")) ||
      "Loading…"}</title
  >
</svelte:head>

<main class="map">
  <nav>
    <header>
      <h1>
        <PropertyDataItem {property} />
      </h1>
      <!-- {#if referrer}
      <a href={referrer}>back</a>
    {/if} -->
      {#if !space}
        {#if issue && !continuous}
          <Form class="editor valid">
            <PolicyPermitValidStep {policy} {values} on:change={change} />
          </Form>
        {/if}
        <ParkingAvailabilityLevels
          level={$level}
          values={availablePolicySpacesByLevel}
          on:change={(e) =>
            change({
              space: null,
              ...e.detail,
            })}
        />
      {/if}
    </header>
    <slot />
    {#if record}
      <section class={record.type}>
        {#if issue}
          <PolicyPermitCreate {policy} {state} {values} on:create={create} />
        {:else}
          <header>
            <h1>
              <figure class="record">
                <RecordItem item={record} />
                <button
                  type="button"
                  on:click={(e) => query({ space: null })}
                  class="close">close</button
                >
              </figure>
            </h1>
          </header>
        {/if}
      </section>
    {:else if issue}
      <ParkingAvailabilitySpaces
        on:change={change}
        values={availablePolicySpacesByLevel?.[$level] ??
          availablePolicySpacesByLevel?.["outside"]}
      />
    {:else}
      <section class="available">
        <SpacesDirectory spaces={policySpaces} />
      </section>
    {/if}
  </nav>
  <PropertyMap
    property={property.id}
    level={$level}
    bind:levels
    selected={space}
    minzoom={16}
  >
    <!-- <MapCompass /> -->
    <MapFeatureState
      state={(feature) => ({
        selectable:
          !issue ||
          !!availablePolicySpaces?.items[
            feature?.properties["ref:boss:subject"]
          ],
        permit: availablePolicySpaces?.items[
          feature?.properties["ref:boss:subject"]
        ]
          ? "no"
          : undefined,
      })}
      {filter}
    />
    {#if $hover}
      <MapFeatureHover {filter} bind:hovered>
        {#if hoveredItem}
          <MapPopup feature={hovered}>
            <h1>
              <RecordItem url={false} item={hoveredItem} />
            </h1>
            <ul>
              {#each Object.values(hoveredItem.prices?.items ?? {}) as item}
                <li>
                  <data class="price" value={item.id}>{item.description}</data>
                </li>
              {/each}
              <!-- {#if hoveredItem?.pricing && !policy.pricing}
                <li>
                  <data class="price" value={hoveredItem.id}
                    >{hoveredItem.pricing.description}</data
                  >
                </li>
              {/if} -->
              {#each Object.values(hoveredItem.pricing?.prices ?? {}) as item}
                <li>
                  <data class="price" value={item.id}>{item.description}</data>
                </li>
              {/each}
            </ul>
          </MapPopup>
        {/if}
      </MapFeatureHover>
    {/if}
    <MapFeatureClick
      {filter}
      on:click={(e) => {
        //logger("map click=", e);
        (!issue ||
          availablePolicySpaces?.items[
            e.detail.properties["ref:boss:subject"]
          ]) &&
          query({ space: e.detail.properties["ref:boss:subject"] });
      }}
    />
  </PropertyMap>
</main>
