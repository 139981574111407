import { derived, type Readable, writable } from "svelte/store";


const params = writable<Params>({});

export const readable = derived(params, ($params) => $params);
export {
  readable as params,
}

export function update(updated: Params) {
  //logger("updating params", params);
  params.set(updated);
}

function paramStore(
  selector: (input: Params) => ParamValues,
  onlyUpdateForRealValues = false,
  defaultValue: string | null = null
): Readable<ParamValue> {
  var value: ParamValue = null;
  return derived(params, (params, set) => {
    if (!params) return;
    var newValue = selector(params);
    if (Array.isArray(newValue) && typeof newValue != "string") newValue = newValue[0];
    if (!newValue && null != defaultValue) newValue = defaultValue;
    if (!newValue && !value) return;
    if (!newValue && onlyUpdateForRealValues) return;
    //logger("setting new paramstore value", newValue);
    if (newValue != value) set((value = newValue));
  });
}

const stores: Record<string, Readable<ParamValue>> = {};

export const param = (
  property: string,
  onlyUpdateWhenSet = false,
  defaultValue: string | null = null
): Readable<ParamValue> =>
  stores[property] ||
  (stores[property] = paramStore(
    ($params: Params) => $params[property],
    onlyUpdateWhenSet,
    defaultValue
  ));

export const single = (property: string, defaultValue: string | null = null): Readable<string | null> => derived(param(property, true, defaultValue), $values => !$values ? null : typeof $values == "string" ? $values : $values[0]);

export const document = derived<typeof params, Document>(params, ($params) => self.document);

//document.subscribe(console.log);