import { base, client, viewpoint, responseJson } from "$utils/api";
//import { image } from "$utils/imagetools";
import { Vehicle } from ".";
//import WorkerFunction from "worker-function";


/**
 * Resize a File object. Returns a promise. On success this promise will return a Blob object.
 * Use FileReader.readAsDataURL to obtain a data URI from this blob.
 */
// async function resize(file: Blob, max_width: number, max_height: number | null = null): Promise<Blob> {
//     if (typeof OffscreenCanvas === 'undefined') {
//         throw new Error('Your browser is unsupported');
//     }

//     if (!(file instanceof File)) {
//         throw new Error('Invalid file argument');
//     }

//     if (!file.type.match(/^image\//)) {
//         throw new Error('This file is not an image');
//     }

//     var img = await createImageBitmap(file, {
//         imageOrientation: "from-image",
//     });



//     // return new Promise((resolve, reject) => {
//     //     let img = new Image;
//     //     img.onload = () => {
//     let n = getNewImageSize(img, max_width, max_height);
//     const canvas = new OffscreenCanvas(n.width, n.height);
//     //canvas.imageSmoothingEnabled = true;
//     const context = canvas.getContext('2d', { alpha: false });
//     //context.imageSmoothingQuality = quality;
//     context?.drawImage(img, 0, 0, img.width, img.height, 0, 0, n.width, n.height);

//     //const o = { type: "image/webp", quality: 0.75 };
//     //const blob = canvas.convertToBlob(o).then((blob) => resolve(blob));
//     return canvas.convertToBlob({
//         type: "image/jpeg",
//         quality: 0.45,
//     });
//     // };

//     // img.onerror = reject;
//     // img.src = url;
//     //});
// }


// Let's create a new worker
// export const resize = WorkerFunction<Blob>(async function resize(file: Blob, max_width: number, max_height: number | null, format: {
//     type: "image/jpeg",
//     quality: 0.45,
// }, done: (image: Blob | null) => void) {
//     if (typeof OffscreenCanvas === 'undefined') {
//         return done(file);
//         //throw new Error('Your browser is unsupported');
//     }

//     if (!(file instanceof File)) {
//         //throw new Error('Invalid file argument');
//         return done(file);
//     }

//     if (!file.type.match(/^image\//)) {
//         //throw new Error('This file is not an image');
//         return done(file);
//     }

//     function getNewImageSize(img: ImageBitmap, width: number, height: number | null = null): { width: number, height: number } {
//         if (height === null) {
//             if (img.width > img.height) {
//                 height = Math.round(img.height * width / img.width)
//             }
//             else if (img.width == img.height) {
//                 height = width;
//             }
//             else {
//                 height = width;
//                 width = Math.round(img.width * height / img.height);
//             }

//             if (img.width < width && img.height < height) {
//                 width = img.width, height = img.height;
//             }
//         }

//         width = Math.abs(width);
//         height = Math.abs(height);
//         return { width, height };
//     }

//     createImageBitmap(file, {
//         imageOrientation: "from-image",
//     }).then((img: ImageBitmap) => {
//         //console.log("have new image", img);
//         let n = getNewImageSize(img, max_width, max_height);
//         const canvas = new OffscreenCanvas(n.width, n.height);
//         //canvas.imageSmoothingEnabled = true;
//         const context = canvas.getContext('2d');
//         //context.imageSmoothingQuality = quality;
//         context?.drawImage(img, 0, 0, img.width, img.height, 0, 0, n.width, n.height);

//         //const o = { type: "image/webp", quality: 0.75 };
//         //const blob = canvas.convertToBlob(o).then((blob) => resolve(blob));
//         return canvas.convertToBlob(format ?? {
//             type: "image/jpeg",
//             quality: 0.75,
//         })
//     }).then(resized => done(resized)).catch(() => done(file));



//     // return new Promise((resolve, reject) => {
//     //     let img = new Image;
//     //     img.onload = () => {


// }) as Promise<Blob>;


export async function fetchPlateObservation(property: string | { id: string }, blob: Blob): Promise<any> {
    const photo = await resize(blob, 1024, 1024);

    //logger(URL.createObjectURL(photo));

    const data = new FormData();
    data.append("image", photo, `image${Date.now()}.jpg`);

    let res = await fetch(
        `https://events.propertyboss.io/image/vehicle?viewpoint=${viewpoint()}`
        // `${base()}/detections/vehicles?viewpoint=${viewpoint()}&scope=${property.id || property
        //}&client=${client}&method=scanner`
        ,
        {
            method: "POST",
            body: photo,
        }
        // {
        //     method: "POST",
        //     body: data,
        // }
    );
    let json = await responseJson(res);
    for (const [k, v] of Object.entries(json.detections?.items ?? {})) {
        json.detections.items[k] = json.items[v as string] ?? json.items[k] ?? v;
    }
    // if(!json.detections) return {
    //     generated: json.generated,
    //     detections: {
    //         generated: json.generated,
    //         count: 1,
    //         items: {
    //             "item": 
    //             {
    //                 "id": "item",
    //                 "plate": "123456",
    //                 "client": client,
    //                 "method": "scanner",
    //             }
    //         },
    //     },
    // }

    return json;
}

export async function fetchImageVehicle(photo: Blob): Promise<Vehicle[]> {
    // const photo = await resize(blob, 1024, 1024, {
    //     type: "image/jpeg",
    //     quality: 0.45,
    // }) as Blob;

    let res = await fetch(
        `https://events.propertyboss.io/image/vehicle?viewpoint=${viewpoint()}`
        ,
        {
            method: "POST",
            body: photo,
        }
    );
    let json = await responseJson(res);
    if (json.items) return Object.values(json.items).map((item: any) => Vehicle.from(item.license_plate_number ?? item.license_plate ?? item.number ?? item.plate, {
        generated: item.generated,
        make: item.make,
        model: item.model,
        color: item.color,
    })) as Vehicle[];
    const plate = json.license_plate_number ?? json.license_plate;
    if (!plate) return [];
    return [
        Vehicle.from(plate, {
            generated: json.generated,
            make: json.make,
            model: json.model,
            color: json.color,
        })
    ].filter(Boolean) as Vehicle[];


    return json;
}