import pick from "lodash-es/pick";

import { api, client } from "./auth";
import { fetchPolicies } from "$components/policy/api";

export { api, client };

export function viewpoint(offset?: number) {
  return encodeURIComponent(Temporal.Now.zonedDateTimeISO().add({ milliseconds: offset || 0 }).toString({
    calendarName: "never",
    timeZoneName: "never"
  }));
}

const apiVersion = "";

export function base(trailingslash?: boolean) {
  const value = api.settings.apiBase + apiVersion;
  if (trailingslash && !value.endsWith("/")) return value + "/";
  return value;
}

function formDataToURLSearchParams(formData) {
  return new URLSearchParams([...formData.entries()]); // what edge does this support?
}

export function coordsToURLSearchParams(coords) {
  return new URLSearchParams(
    Object.entries(
      pick(coords || {}, [
        "latitude",
        "longitude",
        "accuracy",
        "altitude",
        "altitudeAccuracy",
        "speed",
        "heading",
        "headingAccuracy",
      ])
    )
      .filter(([a, b]) => !!b)
      .map(([a, b]) => [a, b + ""])
  );
}

export async function fetchMedia(id: string) {
  if (!id) return null;

  const res = await fetch(`${base()}/media/${id}?viewpoint=${viewpoint()}`);
  const json = await res.json();

  // expand subjects & scopes
  for (const value of Object.values([
    ...Object.values(json.items),
    ...Object.values(json.media?.items || []),
    json.media?.item,
  ]) as any[]) {
    if (!value) continue;
    if (value.scope) value.property = resolveProperty(value.scope, json);
  }

  return json;
}



export function resolveProperty(item, state) {
  if (!item) return item;
  const items = state.items || state;
  item = items[item] || item;
  if (!item) return item;
  if (typeof item === "string") return null;
  return resolveAddress(item, state);
}

function resolveAddress(item, state) {
  if (!item) return item;
  const items = state.items || state;
  item = items[item] || item;
  //logger("address state=", state);
  if (item.address) item.address = items[item.address] || item.address;
  return item;
}


export async function fetchProperty(property) {
  const res = await fetch(
    `${base()}/properties?viewpoint=${viewpoint()}&property=${property}`
  );
  const json = await res.json();

  const item =
    resolveProperty(property, json) ??
    resolveProperty(Object.values(json.properties.items ?? {})[0], json);
  return item;
}


export async function fetchUnits(scope) {
  const res = await fetch(
    `${base()}/units?property=${scope}&viewpoint=${viewpoint()}`
  );

  const json = await res.json();

  for (const value of Object.values(json.items) as any[]) {
    if (value.scope) value.property = resolveProperty(value.scope, json);
  }

  return json;
}

export async function fetchUnit(id: string) {
  const res = await fetch(`${base()}/units?id=${id}&viewpoint=${viewpoint()}`);

  const json = await res.json();

  for (const value of Object.values(json.items) as any[]) {
    if (value.scope) value.property = resolveProperty(value.scope, json);
  }

  return json;
}

export async function fetchSpaceStatus(scope: string, valid?: string) {
  if (!valid) valid = viewpoint() + "/";
  else valid = encodeURIComponent(valid);

  var url = `${base()}/locations/${scope}/permits/spaces/summary?prices=true&viewpoint=${viewpoint()}&valid=${valid}`;
  //logger("spaces=", url);

  const res = await fetch(url);
  //logger("spaces res", res);
  const json = await res.json();

  return json;
}

export async function responseJson(response) {
  if (!response) return {};
  return response
    .text()
    .then(function (text) {
      if (!text)
        return {
          status: response.status,
        };

      return Promise.resolve(text)
        .then(JSON.parse)
        .catch(function (error) {
          return {
            status: response.status,
            message: text,
          };
        });
    })
    .catch(function (error) {
      return {
        status: response.status,
      };
    });
}

export async function fetchViolation(violation, property, vehicle) {
  const url = new URL(
    `${base()}/violations/${(violation?.id ?? violation).replace(
      " ",
      ""
    )}?viewpoint=${viewpoint()}`
  );
  if (vehicle) url.searchParams.set("vehicle", vehicle);
  if (property) url.searchParams.set("location", property?.id || property);
  return fetch(url).then(responseJson);
  // const json = await responseJson(res);
  // return json;
}

export async function fetchProperties(ids) {
  if (!ids || !ids.length) return {};
  var url =
    typeof ids === "string"
      ? `${base()}/properties?viewpoint=${viewpoint()}&q=${ids}`
      : `${base()}/properties?viewpoint=${viewpoint()}${ids
        .map((id) => "&property=" + id)
        .join("")}`;
  const res = await fetch(url);
  const json = await res.json();

  for (const [k, v] of Object.entries(json.properties.items)) {
    json.properties.items[k] = resolveProperty(
      json.items[v as string] ?? json.items[k] ?? v,
      json.items
    );
  }

  return json;

  // updateItems(json);
  // return map(get(json, "properties.items"), (id) =>
  //   resolveProperty(json.items[id], json.items)
  // ); // expand the property first
}

export async function fetchSearchProperties(query) {
  if (!query) return {};
  var url = `${base()}/properties?viewpoint=${viewpoint()}&q=${query}`;
  const res = await fetch(url);
  const json = await res.json();

  for (const [k, v] of Object.entries(json.properties?.items ?? {})) {
    json.properties.items[k] = resolveProperty(
      json.items[v as string] ?? json.items[k] ?? v,
      json.items
    );
  }

  // each(get(json, "properties.items"), id => {
  //     resolveProperty(json.items[id], json.items); // expand the property first
  // })

  return json;
}

export async function fetchGeoProperties(lon, lat, accuracy) {
  var url = `${base()}/properties/?viewpoint=${viewpoint()}&longitude=${lon}&latitude=${lat}&accuracy=${accuracy}`;
  const res = await fetch(url);
  const json = await res.json();

  for (const [k, v] of Object.entries(json.properties.items)) {
    json.properties.items[k] = resolveProperty(
      json.items[v as string] ?? json.items[k] ?? v,
      json.items
    );
  }

  // each(get(json, "properties.items"), id => {
  //     resolveProperty(json.items[id], json.items); // expand the property first
  // })

  return json;
}

export async function fetchDirectory(scope: string, unit: string | null = null, parking: Set<string> = new Set()) {
  var url = new URL(
    `https://carto.communityboss.app/v1/properties/${scope}/directory?viewpoint=${viewpoint()}`
  );
  if (unit) url.searchParams.set("unit", unit);
  for (const item of parking) url.searchParams.append("parking", item);
  //logger("spaces=", url);

  //const dir = fetch(url);
  const pol = fetchPolicies(scope);

  const res = await fetch(url);
  //logger("spaces res", res);
  const json = await res.json();
  const policies = await pol;

  //logger("policies=", policies);

  for (const feature of json.features) {
    if (feature.properties["ref:boss:subject"])
      feature.properties.policy = policies?.items?.[feature.properties["ref:boss:subject"]];
    // feature.properties.policy = resolvePolicy(
    //   feature.properties["ref:boss:subject"],
    //   policies
    // );
  }

  return json;
}
