import WorkerFunction from "worker-function";

export const resize = WorkerFunction<Blob>(async function resize(file: Blob, max_width: number, max_height: number | null, format: {
    type: "image/jpeg",
    quality: 0.45,
}, done: (image: Blob | null) => void) {
    if (typeof OffscreenCanvas === 'undefined') {
        return done(file);
        //throw new Error('Your browser is unsupported');
    }

    if (!(file instanceof File)) {
        //throw new Error('Invalid file argument');
        return done(file);
    }

    if (!file.type.match(/^image\//)) {
        //throw new Error('This file is not an image');
        return done(file);
    }

    function getNewImageSize(img: ImageBitmap, width: number, height: number | null = null): { width: number, height: number } {
        if (height && width) {
            if (img.width > img.height) {
                height = Math.round(img.height * width / img.width)
            } else {
                width = Math.round(img.width * height / img.height);
            }
            return { width, height };
        }

        if (height === null) {
            if (img.width > img.height) {
                height = Math.round(img.height * width / img.width)
            }
            else if (img.width == img.height) {
                height = width;
            }
            else {
                height = width;
                width = Math.round(img.width * height / img.height);
            }

            if (img.width < width && img.height < height) {
                width = img.width, height = img.height;
            }
        }

        width = Math.abs(width);
        height = Math.abs(height);
        return { width, height };
    }

    createImageBitmap(file, {
        imageOrientation: "from-image",
    }).then((img: ImageBitmap) => {
        //console.log("have new image", img);
        let n = getNewImageSize(img, max_width, max_height);
        const canvas = new OffscreenCanvas(n.width, n.height);
        //canvas.imageSmoothingEnabled = true;
        const context = canvas.getContext('2d');
        //context.imageSmoothingQuality = quality;
        context?.drawImage(img, 0, 0, img.width, img.height, 0, 0, n.width, n.height);

        //const o = { type: "image/webp", quality: 0.75 };
        //const blob = canvas.convertToBlob(o).then((blob) => resolve(blob));
        return canvas.convertToBlob(format ?? {
            type: "image/jpeg",
            quality: 0.75,
        })
    }).then(resized => done(resized)).catch(() => done(file));



    // return new Promise((resolve, reject) => {
    //     let img = new Image;
    //     img.onload = () => {


}) as Promise<Blob>;