<script lang="ts">
  // export let url = false;
  // export let item;

  let {
    url = false,
    item,
    children,
  } = $props<{
    url?: boolean;
    item: RecordItem;
    children?: Snippet;
  }>();
  function href(item) {
    return "";
  }
</script>

{#if url}
  <a href={true === url ? href(item) : url}>{@render children?.()}</a>
{:else}
  {@render children?.()}
{/if}
