import store from "store";
import { derived, writable, type Readable } from "svelte/store";

function autocreate(key: string, scope?: string, props: Record<string, any> = {}): Vehicle | null {
  key = plate(key);
  if (!key) return null;
  if (key.length < 3) return null;
  return {
    generated: new Date().toISOString(),
    scope,
    type: "vehicle",
    id: key,
    key,
    display: key,
    ...(props ?? {})
  };
}

function plate(key: string): string {
  if (!key || typeof key !== "string") return null;
  key = key
    .toUpperCase()
    .replace(/[^A-Z0-9]/gi, "")
    .replace("O", "0");
  if (key.length <= 1) return null;
  if (key.length >= 10) return null;
  return key;
}

export class Vehicle implements Typed {
  generated?: string;
  id?: string;
  uuid?: string;
  type: string = "vehicle";
  scope?: string;
  plate?: string;
  make?: string;
  model?: string;
  color?: string;
  year?: number;
  constructor(props: Vehicle, extra: Record<string, any> = {}) {
    //super(props);
    Object.assign(this, props, extra);
  }

  static from(init: Vehicle | string, props: Record<string, any> = {}): Vehicle | null {
    if (null == init) return null;
    if (typeof init === "string") {
      if (!init) return null;
      const autoinit = autocreate(init);
      if (autoinit) return new Vehicle(autoinit);
      return null;
    }
    return new Vehicle(init);
  }
}


const _saved = writable<Record<string, string>>(store.get("vehicles/last") ?? {});

// 
_saved.subscribe((vehicles) => {
  store.set("vehicles/last", vehicles);
});

export function save(vehicle: Vehicle | string) {
  if (!vehicle) return;
  _saved.update((vehicles) => {
    const plate = typeof vehicle === "string" ? vehicle : vehicle.plate || vehicle.key || vehicle.display;
    if (!plate) return vehicles;
    vehicles[plate] = new Date().toISOString();
    //vehicles.count = Object.keys(vehicles.items).length;
    return vehicles;
  });
}

export const saved = derived<typeof _saved, Vehicles>(_saved, ($vehicles) => ({
  items: Object.entries($vehicles).reduce((result, [k, v]) => {
    const vehicle = autocreate(k, undefined, { created: v });
    if (!vehicle) return result;
    const key: string = vehicle.key || vehicle.plate || vehicle.display;
    if (!key) return result;
    result[key] = vehicle;
    return result;
  }, {} as Record<string, Vehicle>),
  count: Object.keys($vehicles).length,
}));