<script lang="ts">
  import { createEventDispatcher, onDestroy } from "svelte";
  import {
    type VectorGLMap,
    type Marker,
    type LngLatLike,
    type MapGeoJSONFeature,
    pointFromFeature,
    type Feature,
  } from "./mapping";
  import { getSvelteContext, engine } from "./mapping";

  export let position: GeolocationCoordinates | nullish;
  export let heading = 0;

  const { map } = getSvelteContext() ?? {};

  // let pitch = 0;

  // map.on("pitchend", updatePitch);
  // map.on("pitch", updatePitch);
  // map.on("pitchstart", updatePitch);

  // on mount
  var el = document.createElement("div");
  el.className = "maplibregl-user-location-dot";

  // function updatePitch() {
  //     requestAnimationFrame(() => {
  // 		pitch = map.getPitch();
  // 		// el.style.transformOrigin = "center";
  // 		// el.style.transform = `rotateX(${pitch}deg);`;
  // 	});
  // }

  const marker: Marker = new engine.Marker({
    //anchor: "bottom-left",
    className: "position-marker",
    element: el,
  }).setPitchAlignment("map");
  // .setLngLat(position)
  // .addTo(map);

  $: if (marker && position && $map) {
    marker.setLngLat([position.longitude, position.latitude]).addTo($map);
  } else {
    marker.remove();
  }

  onDestroy(function () {
    marker.remove();
  });
</script>
