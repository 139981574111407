<script lang="ts">
  import { resize } from "$utils/image";
  import { Vehicle } from ".";
  import { fetchImageVehicle } from "./api";

  let submitting: boolean = $state(false);
  const {
    onvehicle,
    class: classname,
    onfile,
  } = $props<{
    onvehicle?: (vehicle: Vehicle | null) => void;
    onfile?: (file: File) => void;
    class?: string | string[] | nullish;
  }>();

  async function onchange(e: Event) {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (!file) return;

    submitting = true;

    const resized = await resize(file, 1024, 1024, {
      type: "image/jpeg",
      quality: 0.45,
    });
    onfile?.(resized);

    // resize(
    //   file,
    //   1024,
    //   1024,
    //   {
    //     type: "image/jpeg",
    //     quality: 0.45,
    //   },
    //   (resized: Blob) => {
    //     onfile?.(resized);

    //     submitting = true;

    //     if (!onvehicle) return; // no handler for this

    //     // const value = await fetchImageVehicle(resized);

    //     // submitting = false;

    //     // if (!value) return;
    //     // onvehicle?.(value);
    //   }
    // );

    // onfile?.(resized);

    //submitting = true;

    if (!onvehicle) return; // no handler for this

    const value = await fetchImageVehicle(resized);

    submitting = false;

    if (!value) return;
    onvehicle?.(value);
  }
</script>

{#if submitting}
  <figure class="activity"></figure>
{:else}
  <input
    class={["vehicle", classname].filter(Boolean).join(" ")}
    type="file"
    accept="image/*"
    {onchange}
  />
{/if}
