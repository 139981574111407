<svelte:options runes />

<script lang="ts">
  let {
    label = null,
    class: classname = null,
    children,
  } = $props<{
    label?: string | nullish;
    class?: string | nullish;
    children?: Snippet;
  }>();
</script>

<figure class={["value", classname].filter(Boolean).join(" ")}>
  {#if label}
    <figcaption>{label}</figcaption>
  {/if}
  {@render children?.()}
</figure>
