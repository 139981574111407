<script lang="ts">
  import Loading from "$components/util/Loading.svelte";
  import { query } from "$utils/router";
  import PropertyMap from "$components/map/PropertyMap.svelte";
  import PropertyDataItem from "$components/property/PropertyDataItem.svelte";
  import {
    selectedId,
    level,
    directory,
    directoryAll,
    selected,
    leveled,
  } from "$components/geojson/stores";
  import MapMarker from "$components/map/MapMarker.svelte";
  import FeatureDataItem from "$components/geojson/FeatureDataItem.svelte";
  import FeatureSummaryDataItem from "$components/geojson/FeatureSummaryDataItem.svelte";

  import FeatureInfo from "$components/geojson/FeatureInfo.svelte";
  import MapLevelSelect from "$components/map/MapLevelSelect.svelte";
  import { position } from "$utils/uistores";
  import MapPositionMarker from "$components/map/MapPositionMarker.svelte";
  import MapCompass from "$components/map/MapCompass.svelte";
  import PositionWatchButton from "$components/position/PositionWatchButton.svelte";
  import SearchForm from "$components/form/SearchForm.svelte";
  import debounce from "lodash-es/debounce";
  import FeaturesList from "$components/geojson/FeaturesList.svelte";
  import FeaturesSearchResults from "$components/geojson/FeaturesSearchResults.svelte";
  import { event, trackPage, type TrackData } from "$utils/track";
  import ShareButton from "$components/share/ShareButton.svelte";

  export let property: Property;

  let levels: Record<string, Leveled>;
  let placeName: string;
  let tracked = false;

  $: if (property?.id && $directory) {
    let trackData: TrackData = {
      propertyId: property.id,
      propertyName: property.name,
      component: "PropertyDirectorySection",
    };
    if (selectedFeature) {
      const url = new URL(window.location.href);
      const place_id = url.searchParams.get("place");
      $directory.forEach((collection) => {
        collection.features.forEach((feature) => {
          if (feature.id === place_id) {
            placeName = feature.properties?.title || feature.properties?.name;
          }
        });
      });
      event("community_map_navigation", { ...trackData, placeName });
    } else if (!tracked) {
      tracked = true;
      trackPage(trackData);
    }
  }

  $: selectedFeature = $selected;

  $: if ($selectedId || !$selectedId) scrollTo(0, 0);

  let searching: string | nullish = null;
  const search = debounce((value: string) => {
    logger("change=", value, searching);
    searching = value;
  }, 150);

  $: if ($selectedId) searching = null;
</script>

<svelte:head>
  <meta name="theme-color" content="#fff" />
  <title
    >{(property?.name && `Community Map - ${property.name}`) ||
      "Loading…"}</title
  >
</svelte:head>

{#if property}
  <main class="map">
    <nav>
      <header>
        <h1>
          <PropertyDataItem {property} />
          <a href="/{property.type}/{property.id}">menu</a>
        </h1>
        <SearchForm
          name="search"
          title="Search directory"
          placeholder="Search directory"
          value={searching}
          onchange={search}
        >
          <PositionWatchButton />
          <MapLevelSelect
            {levels}
            level={$level}
            on:change={(e) => query({ level: e.detail })}
          />
        </SearchForm>
      </header>
      <FeaturesSearchResults query={searching} source={$directoryAll} />
      <slot />
      {#if !searching}
        {#if selectedFeature}
          <article class="feature details">
            <header>
              <h1>
                <figure class="record">
                  <FeatureSummaryDataItem item={selectedFeature} />
                </figure>
              </h1>
              <nav>
                <ul>
                  <li>
                    <ShareButton url={new URL(document.URL)} />
                  </li>
                  <li>
                    <button
                      type="button"
                      class=" close"
                      on:click={(e) => query({ place: null })}
                      >close
                    </button>
                  </li>
                </ul>
              </nav>
              <!-- <button type="button"
              >change</button
            > -->
            </header>
            <FeatureInfo feature={selectedFeature} />
          </article>
        {:else}
          <section class="directory">
            <!-- <header>
            <PropertyPhoto {property} />
          </header> -->
            <header>
              <h1>Directory</h1>
              <nav>
                <ul>
                  <li>
                    <ShareButton url={new URL(document.URL)} />
                  </li>
                </ul>
              </nav>
            </header>
            {#each $directory ?? [] as group}
              <section>
                <h1>{group.title}</h1>
                <FeaturesList items={group} />
              </section>
            {/each}
          </section>
        {/if}
      {/if}
    </nav>
    <PropertyMap
      property={property.id}
      level={$level}
      bind:levels
      on:level={(e) => query({ level: e.detail }, { history: false })}
      minzoom={16}
    >
      <MapCompass />
      <MapPositionMarker position={$position.coords} />
      {#each $leveled?.features.filter((feature) => feature.id) ?? [] as feature}
        <MapMarker
          selected={selectedFeature && selectedFeature.id === feature.id}
          {feature}
          on:click={(e) => query({ place: e.detail.id })}
          ><FeatureDataItem item={feature}
            >{#if selectedFeature && selectedFeature.id === feature.id && (feature?.properties?.name || feature?.properties?.title)}<dfn
                >{feature?.properties.name || feature?.properties.title}</dfn
              >{/if}</FeatureDataItem
          ></MapMarker
        >
      {/each}
    </PropertyMap>
  </main>
{:else}
  <Loading />
{/if}
