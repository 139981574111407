<svelte:options runes={true} />

<script lang="ts">
  import TextField from "$components/form/TextField.svelte";
  import ValueField from "$components/form/ValueField.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import VehicleScanner from "./VehicleScanner.svelte";
  import VehicleImageInput from "./VehicleImageInput.svelte";
  import VehicleImageDetector from "./VehicleImageDetector.svelte";
  import VehicleSelect from "./VehicleSelect.svelte";
  //import { createEventDispatcher } from "svelte";
  import { Vehicle, saved } from ".";

  let {
    label = "Vehicle",
    name = "vehicle",
    value = null,
    required = false,
    readonly = false,
    scanning = null,
    source = null,
    onchange,
    children,
  } = $props<{
    label?: string | nullish;
    name?: string | nullish;
    value?: Vehicle | string | null;
    required?: boolean;
    readonly?: boolean;
    scanning?: boolean | null | undefined;
    source: Vehicles | null | undefined;
    onchange: (value: Vehicle | null) => void;
  }>();

  let history: Vehicles = $derived.by(() => {
    var items: Record<string, Vehicle> = {};
    for (const [key, value] of Object.entries($saved?.items ?? {})) {
      items[key] = value;
    }
    for (const [key, value] of Object.entries(source?.items ?? {})) {
      items[key] = value;
    }

    return {
      items,
      count: Object.keys(items).length,
    };
  });

  let scan = $state(false);
  let results: Vehicle[] = $state([]);
  let image: File | null = $state(null);

  if (typeof value === "string") change(value); // convert immediately

  // do some conditional logic here
  $effect(() => {
    if (typeof value === "string") change(value); // convert immediately
  });

  //logger("init vehicle field", value);

  // const eventing = createEventDispatcher<{
  //   change: typeof value;
  // }>();

  function change(updated: typeof value) {
    if (typeof updated === "string") updated = Vehicle.from(updated);

    // if (updated && value && updated.id === ((value as Vehicle).id ?? value)) {
    //   value = updated; // store the latest
    //   return;
    // }
    scan = false;

    logger("vehicle field change", updated, "calling onchange");

    // do we need a compare here?
    onchange?.((value = updated));
    //eventing("change", (value = updated));
  }

  function blur(updated: string) {
    setTimeout(() => {
      //if (updated !== textfield.value) return; // no longer has this value
      // const autovalue = Vehicle.from(updated);
      // if (autovalue) change(autovalue);
      change(updated);
    }, 500);
  }

  function search(input: string | nullish) {
    if (!input) return (results = []);
    const created = Vehicle.from(input);
    if (!created) return (results = []);
    results = [created];
  }

  function onvehicle(value: Vehicle | null) {
    console.log("vehicle field onvehicle", value);
    image = null;
    change(value);
  }

  function onfile(value: File) {
    logger("vehicle field onfile", value);
    image = value;
  }

  // $: logger("vehicle field=", value, source);
  // $: logger("saved vehicles", $saved);
</script>

{#if value}
  <ValueField {label}
    ><RecordItem item={value} />{#if !readonly}
      <button class="clear" type="button" onclick={(e) => change(null)}
        >change</button
      >
    {/if}</ValueField
  >
{:else if image}
  <VehicleImageDetector {image} {onvehicle} />
{:else}
  <TextField
    {name}
    {label}
    {required}
    {readonly}
    datalist={Object.values(history?.items ?? {}).map((v) => v.display)}
    placeholder="enter plate/tag"
    on:change={(e) => search(e.detail.value)}
    on:blur={(e) => false && blur(e.detail.value)}
  >
    {#if history.count}
      <VehicleSelect source={history} {onvehicle} />
    {/if}
    <VehicleImageInput {onfile} />
    <!-- {#if scanning}
      <button
        type="button"
        class="scan media"
        class:scanning={scan}
        on:click={(e) => (scan = !scan)}>Scan</button
      >
    {/if} -->
  </TextField>
  <ul class="results">
    {#each results as item}
      <li>
        <button
          class="record"
          type="button"
          value={item.id}
          onclick={(e) => change(e.currentTarget.value)}
          ><RecordItem {item} url={false} /></button
        >
      </li>
    {/each}
  </ul>
{/if}
{@render children?.()}
