<script lang="ts">
  import RecordExtras from "./RecordExtras.svelte";
  import RecordSpaceCovered from "./RecordSpaceCovered.svelte";

  let {
    addressed = null,
    children,
    url = false,
    item,
  } = $props<{
    addressed?: boolean | null | undefined;
    url?: boolean;
    children?: Snippet;
    item: RecordItem;
  }>();

  let building: string | null | undefined = $derived(item?.["building:ref"]);
  let floor: string | null | undefined = $derived(
    item?.level && item?.["level:ref"]
  );

  // export let addressed: boolean | null | undefined = null;

  // export let url = false;
  // export let item;

  // $: building = item?.["building:ref"];
  // $: floor = item?.level && item?.["level:ref"];

  let addrdisplay: string | null | undefined = $derived(
    item?.["addr:unit"] &&
      [item?.["addr:housenumber"], item?.["addr:street"]]
        .filter(Boolean)
        .join(" ")
  );

  // $: addrdisplay =
  //   !item?.["addr:unit"] &&
  //   [item?.["addr:housenumber"], item?.["addr:street"]]
  //     .filter(Boolean)
  //     .join(" ");

  let includeAddressExtra: boolean = $derived(
    addressed ??
      ((!addrdisplay && item?.["addr:housenumber"] && item?.["addr:street"]) ||
        Boolean(building) ||
        Boolean(floor) ||
        !!item.covered ||
        !!item.indoor ||
        !!item.outdoor)
  );

  // $: includeAddressExtra =
  //   addressed ??
  //   ((!addrdisplay && item?.["addr:housenumber"] && item?.["addr:street"]) ||
  //     Boolean(building) ||
  //     Boolean(floor) ||
  //     !!item.covered ||
  //     !!item.indoor ||
  //     !!item.outdoor);

  $effect(() => {
    console.log("RecordContent", item, children);
  });
</script>

{#if item}
  <!-- <dfn><abbr title="Cascading Style Sheets">CSS</abbr></dfn> -->
  <dfn
    ><abbr
      title={[
        item["addr:housenumber"],
        item["addr:street"],
        item["addr:unit"] && `#${item["addr:unit"]}`,
      ]
        .filter(Boolean)
        .join(" ") || item.display}
      >{item["addr:unit"] || addrdisplay || item.display}
      {#if children}
        {@render children()}
      {:else}
        <RecordExtras {item} />
      {/if}
    </abbr></dfn
  >
  {#if includeAddressExtra}
    <address>
      {#if !addrdisplay && item["addr:housenumber"] && item["addr:street"]}
        <data
          class="street"
          value="{item['addr:housenumber']} {item['addr:street']}"
          >{item["addr:housenumber"]} {item["addr:street"]}</data
        >
        <!-- {:else if item["addr:street"]}
          <data class="street" value={item["addr:street"]}
            >{item["addr:street"]}</data
          > -->
      {/if}{#if building}
        <data class="building" value={building}>{building}</data>
      {/if}{#if floor}
        <data class="level" value={floor}>{floor}</data>
      {/if}
      <RecordSpaceCovered {item} label />
    </address>{/if}
{/if}
