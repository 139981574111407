<script lang="ts">
  // export let item;
  let { item } = $props<{
    item: Record;
  }>();
  const attrs = [
    "capacity:charging",
    "capacity:disabled",
    "capacity:lift",
    "capacity:bicycle",
    "capacity:motorcycle",
    "capacity:kayak",
    "placement",
    "leisure",
    "amenity",
    "office",
    "size",
    "nfc",
  ];

  let values = $derived({
    size: (item) => {
      if ("motorcycle" !== item.size && "motorcycle" === item.parking_space)
        return "motorcycle";
      return item.size;
    },
  });
  $effect(() => {
    logger("RecordExtras", item, attrs, values);
  });
  // const values = {
  //   size: (item) => {
  //     if ("motorcycle" !== item.size && "motorcycle" === item.parking_space)
  //       return "motorcycle";
  //     return item.size;
  //   },
  // };
</script>

{#if item.color}<data
    class="color"
    value={item.color.replace(" ", "").toLowerCase()}
  />{/if}{#each attrs.filter((key) => !!item[key]) as key}<data
    class={key.replace(":", "-")}
    value={values[key.replace(":", "-")]?.(item) ?? item[key]}
  />{/each}
