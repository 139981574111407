<svelte:options runes={true} />

<script lang="ts">
  //import { createEventDispatcher } from "svelte";
  import TextField from "./TextField.svelte";
  import Form from "./Form.svelte";
  // export let value: string | nullish = "";
  // export let name: string = "q";
  // export let title: string | nullish = null;
  // export let placeholder: string | nullish = null;
  //let searching: boolean = $state(false);

  let {
    onchange,
    value = "",
    name = "q",
    title = null,
    placeholder = null,
    class: className,
    spellcheck = false,
    searching = false,
  } = $props<{
    value: string | nullish;
    name: string;
    title: string | nullish;
    placeholder: string | nullish;
    class: string | nullish;
    spellcheck: boolean | "true" | "false" | nullish;
    searching: boolean;
    onchange: (value: string, name: string) => void;
  }>();

  function change(name: string, newvalue: string) {
    if (newvalue === value) return;
    value = newvalue;
    if (name && onchange) onchange(value, name);
  }
</script>

<Form
  class={["search", className, true === searching && "searching"]
    .filter(Boolean)
    .join(" ")}
  on:reset={(e) => change(name, "")}
>
  <TextField
    type="search"
    {name}
    {title}
    {placeholder}
    {value}
    required
    autocomplete="off"
    {spellcheck}
    on:change={({ detail }) => change(detail.name, detail.value)}
  />{#if true === searching}
    <figure class="activity"></figure>
  {:else}
    <button disabled={!value} type="reset">clear</button>
  {/if}
  <slot />
</Form>
<!-- <form
  class={["search", className].filter(Boolean).join(" ")}
  class:searching={true === searching}
  on:submit|preventDefault
  on:reset={(e) => change(name, null)}
>
  
</form> -->
