<script lang="ts">
  import RecordContent from "./RecordContent.svelte";
  import RecordLink from "./RecordLink.svelte";
  // export let addressed: boolean | null | undefined = null;
  // export let url = false;
  // export let item;

  let {
    addressed = null,
    url = false,
    item,
    children,
  } = $props<{
    addressed?: boolean | null | undefined;
    url?: boolean;
    item: RecordItem;
    children?: Snippet;
  }>();

  $effect(() => {
    console.log("RecordItem", item, children);
  });
</script>

{#if item}
  <RecordLink {item} {url}>
    <data
      class={["id", item.type, item.format].filter(Boolean).join(" ")}
      value={item.id || item.key}
    >
      <RecordContent {item} {addressed} {children}></RecordContent>
    </data></RecordLink
  >
{/if}
