<script lang="ts" context="module">
  import {
    fetchCreatePolicyPermitSpaceLock,
    fetchDeletePolicyPermitSpaceLock,
  } from "./api";
  const CACHE = new Map<string, any>();
  function locked(policy: PermitIssuePolicy, space: string | nullish) {
    return CACHE.get(space ?? "") ?? null;
  }
  async function lock(policy: PermitIssuePolicy, space: string | nullish) {
    if (!space) return null;
    // check for exising lock
    // const existing = locked(policy, space);
    // if (existing) return existing; // validate exp?
    const res = await fetchCreatePolicyPermitSpaceLock(policy.policy, space);
    const value = res.locked?.token;
    if (value) {
      CACHE.set(space ?? "", value);
      return value;
    }
    return null;
  }
  const now = instant(second);
</script>

<script lang="ts">
  import Submit from "$components/form/Submit.svelte";
  import TimerField from "$components/form/TimerField.svelte";
  import ValueField from "$components/form/ValueField.svelte";
  import Info from "$components/util/Info.svelte";
  import { exp } from "$utils/jwt";
  import { instant, second, zero } from "$utils/temporal";
  import Duration from "$components/temporal/TemporalDuration.svelte";
  import { onDestroy } from "svelte";
  import Field from "$components/form/Field.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";

  export let policy: PermitIssuePolicy;
  export let values: Params;
  //export let error: any | nullish;
  let value: string | null;

  //let value: string | null = null;
  let error: string | null = null;

  let submitting: boolean = false;

  //$: value = locked(policy, values.space); // update value on any change, will generally be null
  $: if (values.space) error = null; // clear error on space change
  $: expires = exp(value ?? "");
  $: expired =
    !!expires && Temporal.Instant.compare(expires, $now.toInstant()) < 1;
  $: logger("expires=", expires?.toString());
  //$: ms = $countdown.total("milliseconds");
  $: duration = Temporal.Duration.from(policy?.space?.locked ?? "PT0S");

  $: required = Temporal.Duration.compare(duration, zero) > 0;
  //$: subject = values.space;
  $: proceed = !required || (value && !expired);

  async function submit() {
    submitting = true;
    error = null;
    logger("submitting");
    value = await lock(policy, values.space);
    if (!value) error = "Unable to hold this space";
    submitting = false;
  }

  onDestroy(() => {
    if (value) fetchDeletePolicyPermitSpaceLock(value);
  });
</script>

{#if required}
  <UiFormFieldList>
    <UiFormFieldItem>
      {#if value && expires}
        <TimerField label="Holding" total={duration} datetime={expires} />
      {:else if error}
        <ValueField label="Holding">
          <Info class="policy error" title={error} />
        </ValueField>
      {:else}
        <Field label="Holding">
          <Duration datetime={duration}>to complete</Duration>
        </Field>
      {/if}
    </UiFormFieldItem>
  </UiFormFieldList>
{/if}
{#if proceed}
  <slot />
{:else}
  <!-- additional info? -->
  <footer>
    <Submit disabled={submitting} on:submit={submit}
      >{submitting ? "Checking" : "Continue"}</Submit
    >
  </footer>
{/if}
