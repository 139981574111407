<script lang="ts">
  import Field from "$components/form/Field.svelte";
  import Option from "$components/form/Option.svelte";
  import Select from "$components/form/Select.svelte";
  import {
    TemporalInstantInterval,
    TemporalZonedDateTimeInterval,
  } from "$utils/temporal";
  import { createEventDispatcher } from "svelte";

  export let label: string = "For";
  export let value:
    | TemporalZonedDateTimeInterval
    | TemporalInstantInterval
    | nullish = null;
  export let items: Map<TemporalZonedDateTimeInterval, string> = new Map<
    TemporalZonedDateTimeInterval,
    string
  >();

  const events = createEventDispatcher<{
    change: typeof value;
  }>();

  let names: Record<string, string> = init(value, items);

  function change(updated: string | nullish) {
    logger("TemporalIntervalNamedField.change=", updated);
    events("change", TemporalInstantInterval.from(updated));
  }

  function init(initvalue: typeof value, inititems: typeof items) {
    logger("TemporalIntervalNamedField.init=", initvalue, inititems);
    if (!initvalue && inititems.size) {
      change(inititems.keys().next().value?.toString());
    } else if (!initvalue && Object.keys(inititems).length) {
      change(Object.keys(inititems)[0]);
    }
    return [...inititems].reduce(
      (acc, [interval, name]) => {
        acc[interval.toString()] = name;
        return acc;
      },
      {} as Record<string, string>
    );
  }

  $: names = init(value, items);

  $: name = value ? (names[value?.toString()] ?? null) : null;
</script>

<Field {label}>
  <time datetime={value?.toString() || ""}>
    <abbr>{name ?? ""}</abbr>
    {#if items.size > 1}
      <Select on:change={(e) => change(e.detail.value)}>
        {#each [...items] as [interval, name], index}
          <Option
            selected={!!value &&
              TemporalZonedDateTimeInterval.compare(value, interval) === 0}
            value={interval.toString()}>{name}</Option
          >
        {/each}
      </Select>
    {/if}
  </time>
</Field>
