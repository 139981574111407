<script lang="ts" context="module">
  let i = 0;
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Input from "./Input.svelte";
  import Field from "./Field.svelte";

  export let type = "text";
  export let name: string | nullish = null;
  export let title: string | nullish = null;
  export let label: string | nullish = null;
  export let placeholder: string | nullish = null;
  export let required = false;
  export let readonly = false;
  export let value: string | nullish = "";
  export let minlength: string | nullish = null;
  export let maxlength: string | nullish = null;
  export let autocomplete: string | nullish = null;
  export let autocorrect: string | nullish = null;
  export let spellcheck: boolean | "true" | "false" | nullish = null;
  export let autocapitalize: string | nullish = null;
  export let multiple = false;
  export let disabled: boolean = false;
  export let datalist: string[] = [];
  export let inputmode:
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search"
    | nullish = null;
  export let pattern: string | nullish = null;

  const id = `${name}-${i++}`;

  if (label && !placeholder) placeholder = required ? "required" : "optional";

  const events = createEventDispatcher<{
    change: {
      name: string;
      value: string;
    };
    blur: {
      name: string;
      value: string;
    };
  }>();

  function change(name: string, newvalue: string) {
    if (newvalue === value) return;
    value = newvalue;
    if (name)
      events("change", {
        name,
        value,
      });
  }
</script>

<Field {label} {id}>
  <Input
    {type}
    {id}
    {name}
    {title}
    {required}
    {readonly}
    {placeholder}
    {value}
    {minlength}
    {maxlength}
    {autocomplete}
    {spellcheck}
    {autocorrect}
    {autocapitalize}
    {disabled}
    {multiple}
    {inputmode}
    {pattern}
    {datalist}
    on:change={({ detail }) => change(detail.name, detail.value ?? "")}
    on:blur={({ detail }) =>
      events("blur", { name: detail.name, value: detail.value })}
  /><slot />
</Field>
