<script lang="ts">
  import { createEventDispatcher, tick } from "svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import Select from "./SpaceSelect.svelte";
  import ValueField from "$components/form/ValueField.svelte";
  import Info from "$components/util/Info.svelte";
  import Warning from "$components/util/Warning.svelte";

  export let label = "Space";
  export let value: Space | string | null = null;
  export let name = "space";
  export let required = false;
  export let readonly = false;
  export let source: Spaces | null;

  $: if (typeof value === "string") change(value); // convert immediately
  $: id = (value as Space)?.id ?? "";
  $: evonly = value && value["capacity:charging"] === value.capacity;

  // $: id = value && (value.id || value)?.toString();

  // $: item = value?.id
  //   ? value
  //   : items?.[value] ?? Object.values(items ?? []).find((i) => i.id === value);

  //$: logger("tenant field=", value, id, item, items);

  const evented = createEventDispatcher<{
    change: typeof value;
  }>();

  async function change(updated: typeof value) {
    // change is responsible for resolving the value
    if (typeof updated === "string") updated = source?.items[updated] ?? null;

    // // are these some version of the same ref?
    // if (updated && value && updated.id === ((value as Vehicle).id ?? value)) {
    //   value = updated; // store the latest
    //   return;
    // }

    value = updated;
    //await tick();
    evented("change", value);
  }
</script>

{#if value}
  <ValueField {label}>
    <RecordItem item={value} url={false} />
    {#if !readonly}
      <button class="clear" type="button" on:click={(e) => change(null)}
        >change</button
      >
    {/if}
  </ValueField>
  {#if evonly}
    <Warning message="Only EVs may park in this space" />
  {/if}
  <slot />
{:else}
  <Select
    placeholder={required ? "select…" : "select…"}
    {label}
    {required}
    items={Object.values(source?.items ?? {})}
    on:change={(e) => change(e.detail)}
  />
  <slot />
{/if}
