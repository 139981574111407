<script lang="ts">
  import { onDestroy } from "svelte";
  import { Vehicle } from ".";
  //import { resize } from "$utils/image";
  import { fetchImageVehicle } from "./api";
  import RecordItem from "$components/record/RecordItem.svelte";
  import Loading from "$components/util/Loading.svelte";

  let { image, onvehicle } = $props<{
    image: Blob;
    onvehicle?: (vehicle?: Vehicle | null) => void;
  }>();

  let local: string | null = $state(URL.createObjectURL(image));
  let result = $state(fetchImageVehicle(image));

  onDestroy(() => {
    if (local) URL.revokeObjectURL(local);
  });
</script>

<figure class="detector vehicle">
  <img src={local ?? ""} alt="Image of the vehicle" />
  {#await result}
    <data class="scanning" value="true">Scanning</data>
  {:then values}
    {#if values.length}
      <ul class="results">
        {#each values as item}
          <button
            type="button"
            class:record={!!item}
            onclick={(e) => onvehicle?.(item)}><RecordItem {item} /></button
          >
        {/each}
      </ul>
    {:else}
      <data class="scanning" value="false">No plate/tag found</data>
    {/if}
  {/await}
  <figcaption>
    <button type="button" class="cancel" onclick={(e) => onvehicle?.()}
      >Cancel</button
    >
  </figcaption>
</figure>
